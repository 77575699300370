import { AxiosResponse } from "axios";
import agent from "../../../app/api/axios";
import { IDescription, ITask } from "../types/admin";

export const fetchGetTasks = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/all_tasks`);
};

export const fetchGetLeaderboard = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/leaderboard`);
};

export const fetchCompleteTask = (
	taskId: number
): Promise<AxiosResponse<any>> => {
	return agent.post(`/general/task`, {
		task: taskId,
	});
};

export const fetchCheckAppPassword = (
	password: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/check_common_password`, {
		password: password,
	});
};

export const fetchAddTask = (
	name: string,
	description: IDescription,
	reward: string,
	active: boolean,
	start: string | null,
	end: string | null,
	password: string,
	secretPhrase: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/general/add_task`, {
		name: name,
		description: description,
		reward: reward,
		active: active,
		start: start,
		end: end,
		password: password,
		secretPhrase: secretPhrase,
	});
};

export const fetchUpdateTask = (
	name: string,
	description: IDescription,
	reward: string,
	active: boolean,
	start: string | null,
	end: string | null,
	password: string,
	id: number,
	secretPhrase: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/general/update_task`, {
		name: name,
		description: description,
		reward: reward,
		active: active,
		start: start,
		end: end,
		password: password,
		id: id,
		secretPhrase: secretPhrase,
	});
};

export const fetchBroadcast = (
	message: string,
	password: string,
	buttonText?: string,
	imageUrlBase64?: string,
	inlineButton?: { text: string; url: string }[]
): Promise<AxiosResponse<any>> => {
	return agent.post(`/general/broadcast`, {
		message: message,
		password: password,
		buttonText: buttonText,
		imageUrlBase64: imageUrlBase64,
		inlineButton: inlineButton
	});
};
